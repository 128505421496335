<template>
  <div class="wrapper" style="width: 100%">
    <div class="btn-back">
      <div>
        <admin-title :title="$route.meta.name"></admin-title>
      </div>
      <div>
        <el-button type="info" @click="$router.back()" size="mini">返回</el-button>
      </div>
    </div>
    <div class="title">用户信息</div>
    <el-descriptions direction="vertical" :column="4" border>
      <el-descriptions-item label="id" :span="1" label-class-name="my-label">{{ detail?.uid }}</el-descriptions-item>
      <el-descriptions-item label="昵称" :span="1" label-class-name="my-label">{{ detail?.user_name }}</el-descriptions-item>
      <el-descriptions-item label="手机号" :span="1" label-class-name="my-label">{{ detail?.mobile }}</el-descriptions-item>
      <el-descriptions-item label="车牌号" :span="1" label-class-name="my-label">{{ detail?.order_no }}</el-descriptions-item>
    </el-descriptions>
    <div class="title">充电站信息</div>
    <el-descriptions direction="vertical" :column="4" border>
      <el-descriptions-item label="充电站" :span="1" label-class-name="my-label">{{ detail.station_name }}</el-descriptions-item>
      <el-descriptions-item label="停车位" :span="1" label-class-name="my-label">{{ detail.space_number }}</el-descriptions-item>
      <el-descriptions-item label="地锁" :span="1" label-class-name="my-label">{{ detail.equipment_id }}</el-descriptions-item>
      <el-descriptions-item label="充电枪" :span="1" label-class-name="my-label">{{ detail.gun_number }}</el-descriptions-item>
    </el-descriptions>
    <div class="title">订单信息</div>
    <el-descriptions direction="vertical" :column="5" border>
      <el-descriptions-item label="订单号" :span="1" label-class-name="my-label">{{ detail.order_no }}</el-descriptions-item>
      <el-descriptions-item label="状态" :span="1" label-class-name="my-label">
        <el-tag size="small" :type="filterStatusType(detail.order_status)">{{ filterStatusName(detail.order_status) }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="开始时间" :span="1" label-class-name="my-label">{{ detail.start_time }}</el-descriptions-item>
      <el-descriptions-item label="结束时间" :span="1" label-class-name="my-label">{{ detail.end_time || '-' }}</el-descriptions-item>
      <el-descriptions-item label="取消时间" :span="1" label-class-name="my-label">{{ detail.cancel_time || '-' }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import { getDetailAPI } from './api'
import { dict_order_appoint_status } from '@/enum/dict.js'
export default {
  name: 'Detail',

  data() {
    return {
      detail: {},
      dict_order_appoint_status
    }
  },

  mounted() {
    this.getDetail()
  },
  computed: {
    user() {
      return this.detail.user
    }
  },
  methods: {
    async getDetail() {
      this.detail = await getDetailAPI(this.$route.params.id)
    },
    filterStatusName(status) {
      return this.dict_order_appoint_status.find(item => item.key == status)?.name
    },
    filterStatusType(status) {
      let type = 'primary'
      switch (status) {
        case 0:
          type = 'primary'
          break
        case 1:
          type = 'warning'
          break
        case 2:
          type = 'danger'
          break
        case 3:
          type = 'success'
          break

        default:
          break
      }
      return type
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  .btn-back {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .title {
    margin: 10px 0;
    font-weight: 600;
  }
}
</style>
